import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueFilters from '@/utils/vueFilters.js';
import "./styles/index.scss"
// import 'swiper/css';

Vue.use(vueFilters);
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
