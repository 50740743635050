<template>
	<div class="flats">
		<flats-main/>
	  <scrollButton />
	</div>
</template>
<script>
import FlatsMain from "@/components/Flats/flats-main";
import FlatsSearch from "@/components/Flats/flats-search";
import FlatsList from "@/components/Flats/flats-list";
import ScrollButton from "@/components/scrollButton.vue";

export default {
	components: {ScrollButton, FlatsList, FlatsSearch, FlatsMain}
}
</script>

<style lang="scss">
.flats {
	background: $sky-blue;

	section {
		padding: 40px 0;
		@include up($md) {
			padding-top: 60px;
			padding-bottom: 160px;
		}
	}
}

</style>