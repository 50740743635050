export default {
    MAPBOX: {
        accessToken: "pk.eyJ1IjoicGxheW5leHQiLCJhIjoiY2w4eTY1ZXVnMGUzcTN3bGhrOTI1c21vNyJ9.NK72nfBXEPiHn29NEZNfhw",
        style: "mapbox://styles/playnext/cl8y5w3xj005j15r0s00yqp4h",
    },

    API_SERVER_URI: 'https://serebro-avenue.ru',
    API_DEFAULT_ACTION: '/api/Form',

    WIDTH_SM: 744,
    WIDTH_MD: 1200,
    WIDTH_LG: 1720
}