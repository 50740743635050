import Vue from 'vue';
import Router from 'vue-router';
import Home from "@/views/Home";
import Flats from "@/views/Flats";
import Project from "@/views/Project";
import Howtobuy from "@/views/Howtobuy";
import Location from "@/views/Location";
import News from "@/views/News";
import NewsItem from "@/views/News-item";
import Error404 from "@/components/Error/error404";
import Error500 from "@/components/Error/error500";

Vue.use(Router);

const routes = [
    {
        path: '/', component: () => import("../layout/layout-main"),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import("../views/Home"),
                meta: {api: "HomeController"}
            },
            {
                path: '/flats',
                name: 'flats',
                component: () => import("../views/Flats"),
                meta: {api: "LevelListController"}
            },
            {
                path: '/flats/choice/:id',
                props: true,
                name: 'flats-choice',
                component: () => import("../views/FlatsChoice"),
                meta: {api: 'LevelItemController'},
            },
            {
                path: '/news',
                name: 'news',
                component: () => import("../views/News"),
                meta: {api: "PubsController"}
            },
            {
                path: '/news/:code',
                props: true,
                name: 'news-item',
                component: () => import("../views/News-item"),
                meta: {api: 'PubController'},
            },
            {
                path: '/project',
                name: 'project',
                component: () => import("../views/Project")
            },

            {
                path: '/howtobuy',
                name: 'howtobuy',
                component: () => import("../views/Howtobuy")
            },

            {
                path: '/location',
                name: 'location',
                component: () => import("../views/Location")
            },

            {
                path: '/contacts',
                name: 'contacts',
                component: () => import('../views/Contacts.vue'),
            },

            {
                path: '/404',
                name: 'error404',
                component: () => import("../components/Error/error404")
            },

            {
                path: '/500',
                name: 'error500',
                component: () => import("../components/Error/error500")
            },
        ]
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

export default routes