<template>
  <section class="location-main">
	<!-- <v-img
			 src="@/assets/Location/location-main/map.jpg"
			 class="d-md-none"
		 /> -->

	<v-container class="location-main__content d-none d-md-block">
	  <v-row>
		<v-col cols="12" md="6" lg="5">
		  <div class="location-main__border">
			<div class="location-main__text">
			  <h2 class="mb-8">Расположение</h2>
			  <address class="mb-8">
				Санкт-Петербург, Серебристый бульвар 18к1
			  </address>
			  <p>
				Локация, в которой расположен Serebro by Avenue-Apart – удивительным образом сочетает в себе живописную
				природу и урбанистический пейзаж. Жилой комплекс идеально подходит тем, кто хочет приобрести современную
				недвижимость, в окружении зеленых насаждений в районе с чистым воздухом. При этом Комплекс находится в
				пешей доступности от городской инфраструктуры, что создаёт привычный горожанину комфорт. В Приморском
				районе отсутствуют крупные промышленные предприятия. Поблизости находятся Удельный парк и ЦПКиО.
				Жильцы без труда доберутся до любой точки в Санкт-Петербурге за полчаса на машине, за счет удобного
				выезда на ЗСД. До метро Пионерская всего 10 минут пешим шагом. 
			  </p>
			</div>
			<div class="location-main__back"></div>
		  </div>
		</v-col>
		<v-col class="d-none d-md-flex" cols="12" md="6" lg="7"></v-col>
	  </v-row>
	</v-container>

	<div class="location-main__map">
	  <MglMap
		  container="project-location__mapbox"
		  :accessToken="accessToken"
		  :mapStyle.sync="style"
		  :center.sync="center"
		  :zoom.sync="zoom"
		  :scrollZoom="scrollZoom"
		  @load="onMapLoaded">
		<MglMarker
			v-for="(marker, index) of markers"
			:key="index"
			:coordinates="marker.geo"
			:offset="marker.offset"
			color="black"/>
		<MglNavigationControl position="top-right"/>
	  </MglMap>
	</div>

	<div class="location-main__content d-md-none">

	  <v-row>
		<v-col cols="12" md="6">
		  <div class="location-main__border">
			<div class="location-main__text">
			  <h2 class="mb-8">Расположение</h2>
			  <address class="mb-8">
				Санкт-Петербург, Серебристый бульвар 18к1
			  </address>
			  <p>
				Локация, в которой расположен Serebro by Avenue-Apart – удивительным образом сочетает в себе живописную
				природу и урбанистический пейзаж. Жилой комплекс идеально подходит тем, кто хочет приобрести современную
				недвижимость, в окружении зеленых насаждений в районе с чистым воздухом. При этом Комплекс находится в
				пешей доступности от городской инфраструктуры, что создаёт привычный горожанину комфорт. В Приморском
				районе отсутствуют крупные промышленные предприятия. Поблизости находятся Удельный парк и ЦПКиО.
				Жильцы без труда доберутся до любой точки в Санкт-Петербурге за полчаса на машине, за счет удобного
				выезда на ЗСД. До метро Пионерская всего 10 минут пешим шагом. 
			  </p>
			</div>
			<div class="location-main__back"></div>
		  </div>
		</v-col>
		<v-col class="d-none d-md-flex" cols="12" md="6"></v-col>
	  </v-row>

	</div>
  </section>
</template>

<style lang="scss">
.location-main {
  position: relative;

  & .mapboxgl-marker {
	& svg {
	  background-image: url("@/assets/Home/Spb-metro-logo.svg");
	  width: 50px;
	  height: auto;
	  background-size: contain;

	  & ellipse,
	  & path,
	  & circle {
		display: none;
	  }
	}

  }

  & .mapboxgl-marker:nth-child(2) {
	& svg {
	  background-image: url("@/assets/Project/project-location/house.png");
	  width: 134px;
	  height: 89px;
	  background-size: cover;

	  & ellipse,
	  & path,
	  & circle {
		display: none;
	  }
	}

  }

  & .mapboxgl-marker:nth-child(3) {
	& svg {
	  background-image: url("@/assets/Home/Spb-metro-logo.svg");
	  width: 50px;
	  height: auto;
	  background-size: contain;

	  & ellipse,
	  & path,
	  & circle {
		display: none;
	  }
	}

  }

  &__map {
	height: calc(100vh - 119px);
	max-height: 600px;
	position: relative;
	cursor: pointer;
	@include up($md) {
	  max-height: unset;
	  //margin-top: 95px;
	}
  }

  &__content {
	padding: 0 24px;
	@include up($md) {
	  padding: 0;
	  position: absolute;
	  top: 15%;
	  width: 100%;
	}
  }

  &__border {
	margin-top: -120px;
	padding: 12px 2px 44px 40px;
	border: 1px solid $dark-grey;
	border-radius: 0px 0px 0px 40px;
	position: relative;
	z-index: 2;
	@include up($md) {
	  margin-top: 0;
	  padding: 28px 0 102px 76px;
	}
  }

  &__text {
	position: relative;
	z-index: 1;
  }

  &__back {
	position: absolute;
	background-color: $sky-blue;
	border-radius: 0 40px;
	//mix-blend-mode: screen;
	width: 100%;
	height: 100%;
	top: -16px;
	left: 16px;
	z-index: -1;

	&::after {
	  position: absolute;
	  content: "";
	  background-image: url("@/assets/Project/project-about/back.png");
	  background-size: cover;
	  border-radius: 0 40px;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  opacity: 0.1;
	  @include up($md) {
		//background-image: unset;
	  }
	}

	@include up($md) {
	  top: -32px;
	  left: 30px;
	  border-radius: 0 40px;
	  & address {
		font-style: normal;
		font-size: 18px;
	  }

	  &__border {
		margin-top: -120px;
		padding: 12px 2px 44px 40px;
		border: 1px solid $dark-grey;
		border-radius: 0px 0px 0px 40px;
		position: relative;
		z-index: 1;
		@include up($md) {
		  margin-top: 0;
		  padding: 28px 0 102px 76px;
		}
	  }

	  &__text {
		position: relative;
		z-index: 1;
	  }

	  &__back {
		position: absolute;
		background-color: $sky-blue;
		border-radius: 0 40px;
		//mix-blend-mode: screen;
		width: 100%;
		height: 100%;
		top: -16px;
		left: 16px;
		z-index: -1;

		&::after {
		  position: absolute;
		  content: "";
		  background-image: url("@/assets/Project/project-about/back.png");
		  background-size: cover;
		  border-radius: 0 40px;
		  width: 100%;
		  height: 100%;
		  top: 0;
		  left: 0;
		  opacity: 0.1;
		  @include up($md) {
			background-image: unset;
		  }
		}

		@include up($md) {
		  top: -32px;
		  left: 30px;
		  border-radius: 0 40px;
		}
	  }
	}
  }
}
</style>

<script>
import BtnForm from "@/components/btn-form";

import Mapbox from "mapbox-gl";
import {MglMap, MglMarker, MglNavigationControl} from "vue-mapbox";
import Config from "@/config";

let mapbox;

export default {
  components: {
	BtnForm,
	MglMap,
	MglMarker,
	MglNavigationControl
  },
  data() {
	return {
	  scrollZoom: false,
	  accessToken: Config.MAPBOX.accessToken,
	  style: Config.MAPBOX.style,
	  center: [30.279675, 60.003912],
	  zoom: 15,
	  point: {
		//offset: [6, -10],
		//offsetPopup: [-10, -16],
		//geo: [30.279675, 60.003912],
	  },
	  markers: [
		{
		  offset: [6, -10],
		  geo: [30.279675, 60.003912],
		  img: '@/assets/Project/project-location/house.png'
		},
		{
		  offset: [6, -10],
		  geo: [30.296666, 60.002518],
		  img: '@/assets/Home/Spb-metro-logo.svg'
		},
		{
		  offset: [6, -10],
		  geo: [30.258027, 60.008573],
		  img: '@/assets/Home/Spb-metro-logo.svg'
		},
	  ]
	};
  },

  created() {
	// We need to set mapbox-gl library here in order to use it in template
	this.mapbox = Mapbox;
  },

  methods: {
	onMapLoaded(event) {
	  this.map = event.map;
	  // or just to store if you want have access from other components
	  this.$store.map = event.map;
	},
  },
};
</script>