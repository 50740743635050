<template>
    <section class="location-distance">
        <v-container>
            <v-row>
                <v-col cols="12" md="7" lg="8">
                    <div class="swiper swiper-2 d-none d-md-block">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide relative"
                                 v-for="(slide, index) of slides"
                                 :key="index">
                                <v-img :src="slide.imgLG"/>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="5" lg="4" class="relative d-md-flex">
                    <div class="location-distance__text">
                    <h2 class="mb-8">Рядом с домом</h2>
                    <p>Недалеко от Serebro by Avenue-Apart есть все, что требуется для комфортной городской жизни.</p>
                    <p>Вы можете посещать огромные гипермаркеты для регулярных покупок или проводить время в торгово-развлекательных центрах.  Для быстрого пополнения запасов, по дороге с работы можно заехать в магазин «у дома», расположенный неподалеку.

					  На цокольном этаже Serebro by Avenue-Apart разместятся магазин, кафе и зона коворкинга. Жильцы и гости смогут посетить их, не покидая пределов Комплекса. Продуманная система безопасности и круглосуточный консьерж-сервис гарантируют, что посторонние люди не проникнут на территорию дома. 

					</p>
                    </div>
					<router-link :to="{ name: 'flats' }"  class="d-none d-md-block mt-auto">
                    <btn>Выбрать квартиру/апартаменты</btn>
					</router-link>
                </v-col>

            </v-row>
        </v-container>
        <div class="swiper swiper-1 d-md-none">
            <div class="swiper-wrapper">
                <div class="swiper-slide relative"
                     v-for="(slide, index) of slides"
                     :key="index">
                    <v-img :src="slide.img"/>
                </div>
            </div>
            <div class="swiper-button-next">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28 20L18 14.2265V25.7735L28 20ZM12 21H19V19H12V21Z" fill="white"/>
                </svg>

            </div>
            <div class="swiper-button-prev">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28 20L18 14.2265V25.7735L28 20ZM12 21H19V19H12V21Z" fill="white"/>
                </svg>

            </div>
        </div>
    </section>
</template>

<style lang="scss">
.location-distance {

    /*& .container {
        max-width: unset;
        padding: 0 24px;
        @include up($md) {
            padding: 0;
            max-width: 1200px;
        }
        @include up($lg) {
            max-width: 1720px;
        }
    }*/

    & .swiper-1 {
        //margin-top: -80px;
        .swiper-slide {
            width: 100%;
            position: relative;
            &:before{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(360deg, rgba(255, 255, 255, 0) 28.94%, rgba(255, 255, 255, 0.8) 100%);
                mix-blend-mode: lighten;
                opacity: 0.8;
                z-index: 1;
            }
        }
        .swiper-button-next {
            //background-image: url("@/assets/Location/location-distance/slider-arrow.svg");
            width: 40px;
            height: 40px;
        }
        .swiper-button-prev {
            //background-image: url("@/assets/Location/location-distance/slider-arrow.svg");
            transform: rotate(180deg);
            width: 40px;
            height: 40px;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            content: "";
        }

        .swiper-button-next,
        .swiper-button-prev {
            &:hover {
                svg path {
                    fill: $primary-1;
                }
            }
            &:active {
                background: $white;
                svg path {
                    fill: $primary-1;
                }
            }

        }
        .swiper-button-disabled {
            background: $light-grey;
            opacity: 1;
            svg path {
                fill: $dark-grey;
            }
        }


    }
    & .swiper-2 {
        max-height: 702px;
        @include up($lg) {
            max-height: 1024px;
        }
        .swiper-slide {
            position: relative;
            &:before{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 35.77%, #FFFFFF 100%);
                mix-blend-mode: lighten;
                opacity: 0.8;
                z-index: 1;
            }
        }
    }
    &__text {
        position: relative;
        z-index: 2;
        &:before {
            position: absolute;
            content: "";
            top: -28px;
            left: -44px;
            width: calc(100% + 60px);
            height: calc(100% + 56px);
            border: 1.5px solid transparent;
            border-image: linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
            border-image-slice: 1;
            @include up($md) {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        @include up($md) {
            padding: 60px 48px;
            position: absolute;
            z-index: 2;
            left: -100px;
            top: 10%;
        }
		@include up($lg) {
			top: 20%;
		}
    }
}
</style>

<script>
import Swiper, {Navigation, Mousewheel} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Btn from "@/components/btn";

export default {
    components: {Btn},
    data() {
        return {
            slides: [
                {
                    img: require('@/assets/Location/location-distance/1.jpg'),
                    imgLG: require('@/assets/Location/location-distance/1-lg.jpg')
                },
                {
                    img: require('@/assets/Location/location-distance/2.jpg'),
                    imgLG: require('@/assets/Location/location-distance/2-lg.jpg')
                },
                {
                    img: require('@/assets/Location/location-distance/3.jpg'),
                    imgLG: require('@/assets/Location/location-distance/3-lg.jpg')
                },
            ]

        }
    },

    mounted: function () {
        var swiper = new Swiper(".swiper-1", {
            direction: "horizontal",
            slidesPerView: 1,
            spaceBetween: 50,
            modules: [Navigation, Mousewheel],
            mousewheel: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                // 640: {
                //   slidesPerView: 1.7,
                //   spaceBetween: 20,
                // },
                744: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 3,
                },
            },
        });

        var swiper2 = new Swiper(".swiper-2", {
            direction: "vertical",
            slidesPerView: 1,
            spaceBetween: 0,
            modules: [Navigation],
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    },
};

</script>