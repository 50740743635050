<template>
  <section class="home-design relative">
    <v-container class="relative pb-3 pb-sm-6 pr-0">
      <h2 class="home-design__white mt-5 mb-10 relative z-index-1">
        Продуманный<br />дизайн
      </h2>
      <div class="swiper relative mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <v-img
              :lazy-src="lazyImg"
              src="@/assets/Home/home-design/slide-1.jpg"
              alt="Slide"
            />
          </div>
          <div class="swiper-slide">
            <v-img
              :lazy-src="lazyImg"
              src="@/assets/Home/home-design/slide-2.jpg"
              alt="Slide"
            />
          </div>
          <div class="swiper-slide">
            <v-img
              :lazy-src="lazyImg"
              src="@/assets/Home/home-design/slide-3.jpg"
              alt="Slide"
            />
          </div>
          <div class="swiper-slide">
            <v-img
              :lazy-src="lazyImg"
              src="@/assets/Home/home-design/slide-4.jpg"
              alt="Slide"
            />
          </div>
          <div class="swiper-slide">
            <v-img
              :lazy-src="lazyImg"
              src="@/assets/Home/home-design/slide-5.jpg"
              alt="Slide"
            />
          </div>
          <div class="swiper-slide">
            <v-img
              :lazy-src="lazyImg"
              src="@/assets/Home/home-design/slide-6.jpg"
              alt="Slide"
            />
          </div>
        </div>
      </div>
      <div class="swiper-button-next swiper-design-next "></div>
      <div class="swiper-button-prev swiper-design-prev"></div>
      <!-- <div class="home-design__border d-none d-lg-block z-index-1"></div> -->
    </v-container>
    <div class="home-design__bg"></div>
  </section>
</template>

<script>
import Swiper, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import lazyImage from "@/utils/lazy-image";

export default {
  computed: {
    lazyImg() {
      return lazyImage;
    },
  },
  mounted: function () {
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      modules: [Navigation],
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-design-next",
        prevEl: ".swiper-design-prev",
      },
      breakpoints: {
        // 640: {
        //   slidesPerView: 1.7,
        //   spaceBetween: 20,
        // },
        744: {
          slidesPerView: 1.7,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 2.5,
          spaceBetween: 50,
        },
      },
    });
  },
};
</script>

<style lang="scss">
.home-design {
  &__bg {
    position: absolute;
    background: url(@/assets/Home/home-design/bg.jpg);
    background-blend-mode: screen;
    top: 0;
    height: 423px;
    width: 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;

    @include up($sm) {
      background-position: center top;
    }
    @include up($lg) {
      height: 732px;
    }
  }

  &__border {
    position: absolute;
    top: 260px;
    left: 60px;
    // TODO gradient
    border: 1px solid $dark-grey;
    width: 632px;
    height: 680px;
  }
  &__white {
    color: $white;
  }

  .swiper {
    width: 100%;
    height: 100%;
    // right: -$container-padding;
    min-width: 336px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 632px;

    @include up($sm) {
      min-width: 372px;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next {
    // right: 20px;
    width: 40px;
    height: 40px;
    background-image: url(@/assets/Home/home-design/next.svg);
    left: 80px;
    top: 105%;
    @include transition;
    &:hover {
      background-image: url(@/assets/Home/home-design/next-hover.svg);
    }
    &:active {
      background-image: url(@/assets/Home/home-design/next-hover.svg);
    }
    &:disabled {
      background-image: url(@/assets/Home/home-design/next-dis.svg);
    }
    @include up ($lg) {
      left: 115px;
    }
  }
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-image: url(@/assets/Home/home-design/prev.svg);
    left: 30px;
    top: 105%;
    @include transition;
    &:hover {
      background-image: url(@/assets/Home/home-design/prev-hover.svg);
    }
    &:active {
      background-image: url(@/assets/Home/home-design/prev-hover.svg);
    }
    &:disabled {
      background-image: url(@/assets/Home/home-design/prev-dis.svg);
    }
    @include up ($lg) {
      left: 65px;
    }
  }
  .swiper-button-prev:after {
    display: none;
  }
  // .swiper-button-disabled {
  //   pointer-events: all;
  //   opacity: 1;
  // }
}
</style>