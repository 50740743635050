<template>
	<section class="flats-list">
		<v-container>
			<v-row class="d-none d-md-flex">
				<v-col md="3">
					<div>Планировка</div>
				</v-col>
				<v-col md="2">
					<div>Помещение</div>
				</v-col>
				<v-col md="2">
					<div>Общая площадь</div>
				</v-col>
				<v-col md="2">
					<div>Очередь</div>
				</v-col>

			</v-row>

			<v-row>
				<div v-for="(flat, index) of items" :key="index"
					 class="d-md-none flats-list__items">
					<v-img v-if="imgPlan" :src="imgPlan(flat)"
						   class="flats-list__img ma-auto"
						   contain
					/>
					<div class="mt-10 mb-8">{{ flat.TYPE }} №{{ flat.NAME }}</div>
					<ul>
						<li>
							Общая площадь
							<span>{{ flat.SQUARE }} м<sup>2</sup></span>
						</li>
						<!--						<li>
													Цена
													<span>{{ flat.flatPrice }} Р</span>
												</li>
												<li>
													Цена за м2
													<span>345 000 Р</span>
												</li>-->
						<li class="flats-list__items-description">
							<div v-for="(item, index) in flat.OPTIONS"
								 :key="index"
							>
								<!-- todo mt-5 на первом ли -->
								{{ item }}
							</div>
						</li>
						<div v-if="flat.PRICE" class="flats-search__apart-price mt-5 mb-5 size-sm">
							<div class="d-flex justify-space-between color-black">
								<span>Цена за м<sup>2</sup></span>
								<span>{{ flat.PRICE_FOR_M | nf }}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span>Цена</span>
								<span>{{ flat.PRICE | nf }}</span>
							</div>

						</div>

						<div class="flats-list__btns-mobile">
							<popup title="Записаться на просмотр" class="mr-10">Записаться на просмотр</popup>
              <popup v-if="queue === '2' && (flat.LEVEL_IDS[0]=== '49' || flat.LEVEL_IDS[0] === '50' || flat.LEVEL_IDS[0] === '51')"
                     title="знать цену"
                     heading="true"
              >Узнать цену
              </popup>
              <popup v-else title="Задать вопрос">Задать вопрос</popup>
						</div>
					</ul>
				</div>

				<div v-for="(flat, index) of items"
					 :key="'index' + index"
					 class="flats-list__items relative d-none d-md-flex">
					<v-col md="3">
						<v-img v-if="imgPlan" :src="imgPlan(flat)"
							   class="flats-list__img"
							   contain
						/>
					</v-col>
					<v-col md="2">
						<ul>
							<span class="d-block mb-4">{{ flat.TYPE }}<br>
							№ {{ flat.NAME }}</span>
							<li v-for="(item, index) in flat.OPTIONS"
								:key="index"
							>
								<!-- todo mt-5 на первом ли -->
								{{ item }}
							</li>
							<li>Высота потолка {{ apartmentHeight }}</li>
							<li>{{ flat.TYPE }}</li>
							<div v-if="flat.PRICE" class="flats-search__apart-price mt-5 size-sm">
								<div class="d-flex justify-space-between color-black">
									<span>Цена за м<sup>2</sup></span>
									<span>{{ flat.PRICE_FOR_M | nf }}</span>
								</div>
								<div class="d-flex justify-space-between">
									<span>Цена</span>
									<span>{{ flat.PRICE | nf }}</span>
								</div>

							</div>
						</ul>
					</v-col>
					<v-col md="2">
						<div>
							{{ flat.SQUARE }} м<sup>2</sup>
						</div>
					</v-col>
					<v-col md="2">
						<ul>
							{{ queue }} очередь
							<li>3 кв. 2023 г.</li>
						</ul>
					</v-col>
					<!--					<v-col md="2">
											<div>
												{{ flat.flatPrice }} Р
											</div>
										</v-col>
										<v-col md="1">
											<div>345 000 Р</div>
										</v-col>-->
					<div class="flats-list__btns d-none d-md-flex">
						<popup title="Записаться на просмотр" class="mr-10">Записаться на просмотр</popup>
						<popup v-if="queue === '2' && (flat.LEVEL_IDS[0]=== '49' || flat.LEVEL_IDS[0] === '50' || flat.LEVEL_IDS[0] === '51')"
							   title="знать цену"
							   heading="true"
						>Узнать цену
						</popup>
						<popup v-else title="Задать вопрос">Задать вопрос</popup>
					</div>
				</div>
				<!--        <button class="mt-10 ma-auto">Показать еще 10</button>-->
			</v-row>
		</v-container>
	</section>
</template>

<script>
import BtnForm from "@/components/btn-form";
import Popup from "@/components/popup";

export default {
	components: {BtnForm, Popup},
	props: {
		items: Array,
	},
	/*data() {
		return {
			flats: [
				{
					img: require('@/assets/Flats/flats-search/1.png'),
					name: '2-к апартаменты №100',
					options: {
						window: 'Окна во двор',
						height: 'Высота потолка 4,5м',
						steps: '2-уровневые апартаменты'
					},
					square: '26,66 м2',
					order: '1 очередь',
					readyness: '3 кв. 2023 г.',
					price: '10 000 000 Р',
					priceM: '345 000 Р'
				},
				{
					img: require('@/assets/Flats/flats-search/2.png'),
					name: '2-к апартаменты №102',
					options: {
						window: 'Окна во двор',
						height: 'Высота потолка 4,5м',
						steps: '2-уровневые апартаменты'
					},
					square: '26,66 м2',
					order: '1 очередь',
					readyness: '3 кв. 2023 г.',
					price: '10 000 000 Р',
					priceM: '345 000 Р'
				},
				{
					img: require('@/assets/Flats/flats-search/3.png'),
					name: '2-к апартаменты №103',
					options: {
						window: 'Окна во двор',
						height: 'Высота потолка 4,5м',
						steps: '2-уровневые апартаменты'
					},
					square: '26,66 м2',
					order: '1 очередь',
					readyness: '3 кв. 2023 г.',
					price: '10 000 000 Р',
					priceM: '345 000 Р'
				},
				{
					img: require('@/assets/Flats/flats-search/4.png'),
					name: '2-к апартаменты №104',
					options: {
						window: 'Окна во двор',
						height: 'Высота потолка 4,5м',
						steps: '2-уровневые апартаменты'
					},
					square: '26,66 м2',
					order: '1 очередь',
					readyness: '3 кв. 2023 г.',
					price: '10 000 000 Р',
					priceM: '345 000 Р'
				},
				{
					img: require('@/assets/Flats/flats-search/5.png'),
					name: '2-к апартаменты №105',
					options: {
						window: 'Окна во двор',
						height: 'Высота потолка 4,5м',
						steps: '2-уровневые апартаменты'
					},
					square: '26,66 м2',
					order: '1 очередь',
					readyness: '3 кв. 2023 г.',
					price: '10 000 000 Р',
					priceM: '345 000 Р'
				},
			]
		}
	},*/
	computed: {
		apartmentHeight() {
			if (this.$store.state.page?.LevelItemController?.item.STAGE === '37') return '4.6'
			else return '2.9';
		},
		queue() {
			if (this.$store.state.page?.LevelItemController?.item.STAGE === '37') return '2'
			else return '1';
		},
		plan() {
			return this.$store.state.page?.LevelItemController?.plans;
		},
		appartments() {
			return this.$store.state.page?.LevelItemController?.appartments;
		}
	},
	methods: {
		imgPlan(item) {
			if (item.PLAN) return this.plan.find(el => el.ID === item.PLAN).IMAGES[0]
			else return;
		},
	},
	mounted() {
		// console.log(this.imgPlan)
	}
}
</script>

<style lang="scss">
.flats-list {

	background: $white;
	@include up($md) {
		background: $sky-blue;
	}

	ul li {
		//display: block;
		padding-left: 0;
	}

	ul {
		padding-left: 0;
	}

	//&__img {
	//  width: 224px;
	//  height: 224px;
	//  margin: 0 auto;
	//}

	&__items {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 40px 0;

		@include up($md) {
			flex-direction: row;
		}

		&-description {
			flex-wrap: wrap;
			gap: 15px;
		}


		&:not(:last-child) {
			border-bottom: 1px solid #C1CFD8;
		}

		& ul {
			font-weight: 300;
			font-size: 24px;
			line-height: 130%;
		}

		& li {
			color: $dark-grey;
			font-weight: 300;
			font-size: 20px;
			line-height: 120%;

			display: flex;
			justify-content: space-between;

			& span {
				font-size: 20px;
				line-height: 130%;
				color: $black;
			}

			@include up($md) {
				display: block;
			}

			&:first-child {
				@include up($md) {
					margin-top: 23px;
				}
			}

			&:not(:last-child) {
				margin-bottom: 13px;
				@include up($md) {
					margin-bottom: 10px;
				}
			}
		}
	}

	&__img {
		max-width: 250px;
		max-height: 350px;
	}

	&__btns {
		display: flex;
		position: absolute;
		bottom: 60px;
		right: 0;

		@include up($xl) {
			top: 60px;
			bottom: unset;
		}

		&-mobile {
			position: relative;
			flex-direction: column;
			max-width: 300px;
			margin: 0 auto;

			& .popup {
				button {
					width: 300px;

					&:first-child {
						margin-bottom: 20px;
					}
				}
			}

		}
	}

	.popup {
		.v-btn {
			max-width: unset;
			padding: 12px 25px;
		}
	}

	//.btn-form {
	//  width: unset;
	//  max-width: unset;
	//  padding: 12px 25px;
	//
	//  &:first-child {
	//    margin-right: 40px;
	//  }
	//}
}
</style>

