<template>
    <div class="location">
        <location-main/>
        <pros/>
        <location-panoram/>
        <location-distance/>
	  <scrollButton />
    </div>
</template>

<style lang="scss">
.location {
    background: $sky-blue;

    section {
        padding-bottom: 80px;
        @include up($md) {
            padding-bottom: 160px;
        }
    }


}
</style>
<script>
import LocationMain from "@/components/Location/location-main";
import Pros from "@/components/pros";
import LocationPanoram from "@/components/Location/location-panoram";
import LocationDistance from "@/components/Location/location-distance";
import ScrollButton from "@/components/scrollButton.vue";
export default {
    components: {ScrollButton, LocationDistance, LocationPanoram, Pros, LocationMain}
}
</script>