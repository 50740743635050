<template>
	<section class="howtobuy-terms">
		<v-container>
			<h3 class="mb-16">Выгодные условия</h3>

			<v-row>
				<v-col cols="12" md="4" v-for="(item, index) of items"
					   :key="index">
					<div class="howtobuy-terms__content">
						<div>
							<h4 class="mb-10"> {{ item.termLG }} </h4>
							<p class="mb-12 size-sm-xl"> {{ item.textLG }} </p>
						</div>
						<popup :title=item.termLG :class1="'style1'">
							<btn class="howtobuy-terms__popup"> {{ item.btn }}</btn>
						</popup>

					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-row class="d-md-none">
			<v-col cols="12" v-for="(item, index) of items2"
				   :key="index">
				<div class=" howtobuy-terms__content howtobuy-terms__content-img">
					<v-img
						:src="item.img"
						class="mb-4"
					></v-img>
					<v-container>
						<div>
							<h4 class="mb-8"> {{ item.term }} </h4>
							<p class="mb-10 size-sm-xl"> {{ item.text }} </p>
							<popup :title=item.term :class1="'style1'">
								<btn class="howtobuy-terms__popup"> {{ item.btn }}</btn>
							</popup>
						</div>
					</v-container>
				</div>

			</v-col>
		</v-row>
		<v-container>
			<v-row class="d-none d-md-flex">
				<v-col>
					<div class="howtobuy-terms__block">
						<div class="howtobuy-terms__block-first">
							<h4 class="mb-10"> Оформление<br> в течение 1 дня </h4>
							<p class="mb-12 size-sm-xl"> Оформляем собственность в день подписания договора
								купли-продажи. </p>
							<popup title="Оформление в течение 1 дня" :class1="'style1'">
								<btn class="howtobuy-terms__popup"> Оставить заявку</btn>
							</popup>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-row class="d-none d-md-flex justify-end">
			<v-col cols="10">
				<v-img
					class="howtobuy-terms__block-img ml-auto"
					src="@/assets/Howtobuy/howtobuy-terms/terms-lg.jpg"
				></v-img>
			</v-col>
		</v-row>
		<v-container>
			<v-row class="d-none d-md-flex">
				<v-col cols="12">
					<div class="howtobuy-terms__block">
						<v-img class="howtobuy-terms__block-img mr-auto ml-0"
							   src="@/assets/Howtobuy/howtobuy-terms/terms2-lg.jpg"
						></v-img>
						<div class="howtobuy-terms__block-second">
							<h4 class="mb-10"> Дистанционное оформление сделки </h4>
							<p class="mb-12 size-sm-xl">Мы дистанционно оформляем приобретенные квартиры в собственность
								без бумажной волокиты и поездок по инстанциям.</p>
							<popup title="Дистанционное оформление сделки" :class1="'style1'">
								<btn class="howtobuy-terms__popup">Оставить заявку</btn>
							</popup>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>

	</section>
</template>

<style lang="scss">
.howtobuy-terms {

	& .btn, {
		max-width: 360px;
	}

	.popup {
		display: flex;
		justify-content: flex-end;

	}

	p {
		color: $black;
	}

	&__content {
		height: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 100px;
		@include up($sm) {
			margin-bottom: 0;
		}

		@include up($md) {
			justify-content: space-between;
		}

		&-img {
			margin-top: 80px;
			margin-bottom: 0;
		}


		button {
			display: block;
			margin-top: auto;
		}
	}

	&__block {
		margin-top: 160px;
		position: relative;

		&-img {
			max-height: 900px;
			width: fit-content;
		}

		&-first {
			margin-bottom: 160px;
			padding: 64px 48px;
			position: absolute;
			z-index: 1;
			margin-top: 15%;
			left: 0;
			max-width: 592px;
			background-color: rgb(100%, 100%, 100%, 0.3);

			&:before {
				position: absolute;
				content: "";
				top: -12px;
				left: -6px;
				width: 100%;
				height: 100%;
				border: 1.5px solid transparent;
				border-image: linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
				-moz-border-image: -moz-linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
				-webkit-border-image: -webkit-linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
				border-image-slice: 1;
			}

		}

		&-second {
			padding: 64px 48px;
			position: absolute;
			top: 25%;
			right: 0;
			max-width: 592px;
			background-color: rgb(100%, 100%, 100%, 0.3);
			@include up($lg) {
				right: 10%;
			}

			&:before {
				position: absolute;
				content: "";
				top: -12px;
				left: -6px;
				width: 100%;
				height: 100%;
				border: 1.5px solid transparent;
				border-image: linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
				-moz-border-image: -moz-linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
				-webkit-border-image: -webkit-linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
				border-image-slice: 1;
			}
		}
	}
}
</style>

<script>
import Btn from "@/components/btn";
import Popup from "@/components/popup";

export default {
	name: "howtobuy-terms",
	components: {Btn, Popup},
	data() {
		return {
			items: [
				{
					//term: 'Рассрочка',
					//text: 'У нас действуют специальные условия по рассрочке.',
					btn: 'Оставить заявку',
					termLG: 'Оплата 100%',
					textLG: 'При единовременной оплате стоимости жилья предлагаем самую выгодную цену со скидкой.'
				},
				{
					//term: 'Предчистовая отделка в подарок',
					//text: 'Специальные условия для квартир в 1 очереди.',
					btn: 'Оставить заявку',
					termLG: 'Рассрочка',
					textLG: 'У нас действуют специальные условия по рассрочке.'
				},
			],
			items2: [
				{
					img: require('@/assets/Howtobuy/howtobuy-terms/terms-xs.jpg'),
					imgLG: require('@/assets/Howtobuy/howtobuy-terms/terms-lg.jpg'),
					term: 'Оформление в течение 1 дня',
					text: 'Оформляем собственность в день подписания договора купли-продажи.',
					btn: 'Оставить заявку'
				},
				{
					img: require('@/assets/Howtobuy/howtobuy-terms/terms-xs.jpg'),
					imgLG: require('@/assets/Howtobuy/howtobuy-terms/terms2-lg.jpg'),
					term: 'Дистанционное оформление сделки',
					text: 'Мы дистанционно оформляем приобретенные квартиры в собственность без бумажной волокиты и поездок по инстанциям.',
					btn: 'Оставить заявку'
				},

			],
		}
	},
	computed: {
		isWindowMD() {
			return this.$store.getters.isWindowMD;
		},
	},
	mounted() {
		console.log('isWindowMD', this.isWindowMD);
	}
}
</script>