<template>
  <section class="home-location">
	<!--    <div class="home-location__map d-sm-none">
			<MglMap
				:accessToken="accessToken"
				:mapStyle.sync="style"
				:center.sync="center"
				:zoom.sync="zoom"
				:scrollZoom="scrollZoom"
				@load="onMapLoaded">
				<MglMarker
					:coordinates="point.geo"
					:offset="point.offset"
					color="black"/>
				<MglNavigationControl position="top-right"/>
			</MglMap>
		</div>-->

	<v-container>
	  <v-row class="d-flex justify-end flex-column-reverse flex-sm-row">
		<v-col cols="12" sm="2" class="relative">
		  <div class="home-location__border">
			<div class="home-location__text px-6 py-8 pa-md-15">
			  <h2 class="mb-8 mb-lg-12">Удобное расположение</h2>
			                    <address class="mb-8 mb-md-12">
			                      Санкт-Петербург, Серебристый бульвар 18к1
			                    </address>
			  <p class=" mb-md-12">
				Serebro by Avenue-Apart 4* расположен в Приморском районе Санкт-Петербурга, известном красотой, обилием
				зелени и чистым воздухом. В округе мало промышленных предприятий, неподалеку Удельный парк и
				ЦПКиО. Комплекс находится в пешей доступности от метро Пионерская и Комендантский проспект. За счет
				удобного выезда на ЗСД, дорога до аэропорта или любого района займет около 30 минут. 
			  </p>

			  <router-link :to="{ name: 'location' }">
				<btn>О расположениии</btn>
			  </router-link>
			</div>
		  </div>
		</v-col>
		<v-col cols="12" sm="10">
		  <div class="home-location__map">
			<MglMap
				:accessToken="accessToken"
				:mapStyle.sync="style"
				:center.sync="center"
				:zoom.sync="zoom"
				:scrollZoom="scrollZoom"
				@load="onMapLoaded">
			  <MglMarker
				  v-for="(marker, index) of markers"
				  :key="index"
				  :coordinates="marker.geo"
				  :offset="marker.offset"
				  color="black"/>
			  <MglNavigationControl position="top-right"/>
			</MglMap>
		  </div>
		</v-col>
	  </v-row>
	</v-container>
  </section>
</template>

<script>
import Btn from "@/components/btn";
import Mapbox from "mapbox-gl";
import {MglMap, MglMarker, MglNavigationControl} from "vue-mapbox";
import Config from "@/config";

let mapbox;

export default {
  components: {
	Btn,
	MglMap,
	MglNavigationControl,
	MglMarker
  },
  data() {
	return {
	  scrollZoom: false,
	  accessToken: Config.MAPBOX.accessToken,
	  style: Config.MAPBOX.style,
	  center: [30.279675, 60.003912],
	  zoom: 13,
	  point: {
		//offset: [6, -10],
		//offsetPopup: [-10, -16],
		//geo: [30.279675, 60.003912],
	  },
	  markers: [
		{
		  offset: [6, -10],
		  geo: [30.279675, 60.003912],
		  img: '@/assets/Project/project-location/house.png'
		},
		{
		  offset: [6, -10],
		  geo: [30.296666, 60.002518],
		  img: '@/assets/Home/Spb-metro-logo.svg'
		},
		{
		  offset: [6, -10],
		  geo: [30.258027, 60.008573],
		  img: '@/assets/Home/Spb-metro-logo.svg'
		},
	  ]
	};
  },

  created() {
	// We need to set mapbox-gl library here in order to use it in template
	this.mapbox = Mapbox;
  },

  methods: {
	onMapLoaded(event) {
	  this.map = event.map;
	  // or just to store if you want have access from other components
	  this.$store.map = event.map;
	},
  },
};
</script>

<style lang="scss">
.home-location {

  & .mapboxgl-ctrl-bottom-left,
  & .mapboxgl-ctrl-bottom-right {
	display: none;
  }

  & .mapboxgl-marker {
	& svg {
	  background-image: url("@/assets/Home/Spb-metro-logo.svg");
	  width: 50px;
	  height: auto;
	  background-size: contain;

	  & ellipse,
	  & path,
	  & circle {
		display: none;
	  }
	}

  }

  & .mapboxgl-marker:nth-child(2) {
	& svg {
	  background-image: url("@/assets/Project/project-location/house.png");
	  width: 134px;
	  height: 89px;
	  background-size: cover;

	  & ellipse,
	  & path,
	  & circle {
		display: none;
	  }
	}

  }

  & .mapboxgl-marker:nth-child(3) {
	& svg {
	  background-image: url("@/assets/Home/Spb-metro-logo.svg");
	  width: 50px;
	  height: auto;
	  background-size: contain;

	  & ellipse,
	  & path,
	  & circle {
		display: none;
	  }
	}

  }

  .btn {
	justify-content: end;
  }

  &__map {
	height: calc(100vh - 119px);
	max-height: 480px;
	position: relative;
	//filter: grayscale(100%) brightness(100%);
	@include up($sm) {
	  max-height: 550px;
	  right: -$container-padding-sm;
	}
	@include up($md) {
	  right: unset;
	  min-height: 800px;
	  //max-width: 1000px;
	}
  }

  &__text {
	position: relative;
	top: -15px;
	left: 15px;
	background-color: $light-grey-2;
	border-radius: 0 40px;
	z-index: 2;

	&::after {
	  position: absolute;
	  content: "";
	  background-image: url("@/assets/Project/project-about/back.png");
	  background-size: cover;
	  border-radius: 0 40px;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  opacity: 0.04;
	  @include up($md) {
		border-radius: 0 80px;
	  }
	}

	@include up($sm) {
	  min-width: 340px;
	  z-index: 2;
	}
	@include up($md) {
	  top: -30px;
	  left: 30px;
	  max-width: 570px;
	  border-radius: 0 80px;
	  z-index: 2;
	}
  }

  &__border {
	position: relative;
	top: -170px;
	border: 1px solid $dark-grey;
	border-radius: 0px 0px 0px 40px;
	@include up($sm) {
	  min-width: 340px;
	  top: -130px;
	  z-index: 1;
	}
	@include up($md) {
	  min-width: 570px;
	  border-radius: 0 80px;
	}
  }

  & address {
	font-size: 18px;
	line-height: 130%;
	font-style: normal;
  }

  &__img {
	min-width: 360px;
	min-height: 480px;
  }

  .btn {
	background-color: unset !important;
  }
}
</style>