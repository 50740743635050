<template>
	<div class="flats-main-popup d-flex flex-column">
		<div>{{ item.STAGE === '36' ? 'Очередь 1' : 'Очередь 2' }}</div>
		<div class="flats-main-popup__stage">Этаж {{ item.NAME }}</div>


		<div class="flats-main-popup__type">
			{{ Object.keys(item.countAppartments)[0] }}
			{{ Object.values(item.countAppartments)[0] }}
		</div>
		<div class="flats-main-popup__type">
			{{ Object.keys(item.countAppartments)[1] }}
			{{ Object.values(item.countAppartments)[1] }}</div>
		<div class="flats-main-popup__type">
			{{ Object.keys(item.countAppartments)[2] }}
			{{ Object.values(item.countAppartments)[2] }}</div>
	</div>
</template>

<script>
export default {
	name: "flats-main-popup",
	props: {
		item: Object,
	},
	mounted() {
		 //console.log(this.item)
	}
}
</script>

<style lang="scss">
.flats-main-popup {
	padding: 32px;
	background: $white;
	position: absolute;
	top: 5%;
	left: 2%;
	width: 284px;
	//height: 278px;
	z-index: 3;

	&__stage {
		font-weight: 700;
	}

	&__type {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	div {
		display: flex;
		justify-content: space-between;

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&:nth-child(2) {
			font-size: 32px;
		}
	}
}

</style>