<template>
  <section class="home-intro">
	<v-container class="pa-0 pt-0 d-sm-none">
	  <div>
		<div cols="12" class="pa-0 relative">
		  <v-img
			  src="@/assets/Home/home-intro/mobile.jpg"
			  alt="Serebro"
			  class="home-intro__img d-sm-none"
		  />
		  <div class="home-intro__title z-index-1"><h1>Serebro</h1></div>
		  <div class="home-intro__title home-intro__title--1 size-xl z-index-1 color-white">
			Дом в престижном<br/>
			районе Петербурга
		  </div>
		</div>
		<div cols="12" class="relative pa-0">
		  <div absolute class="home-intro__border"></div>
		  <div
			  class="
              home-intro__stats
              size-sm-md
              text-md-center
              d-sm-none d-md-block
            "
		  >

<!--			<p>
			  2-х уровневые <br> апартаменты высотой до 4,6 м
			</p>-->
			<h3 class="mb-2">640 м<sup>2</sup></h3>
			<p>
			  под кафе, магазин<br/>
			  и коворкинг в доме
			</p>
			<h3 class="mb-2">30 минут</h3>
			<p>
			  на машине до аэропорта<br/>
			  или любого района города
			</p>
			<h3 class="mb-2">145 м<sup>2</sup></h3>
			<p>
			  роскошного лобби
			</p>
		  </div>
		</div>
	  </div>
	</v-container>
	<v-container
		class="d-none d-sm-block pa-sm-6 pt-sm-0 relative z-index-2 pt-md-1"
	>
	  <v-img
		  src="@/assets/Home/home-intro/ipad.jpg"
		  alt="Serebro"
		  class="home-intro__img d-none d-sm-block d-md-none"
	  />
	  <v-img
		  src="@/assets/Home/home-intro/desktop.jpg"
		  alt="Serebro"
		  class="home-intro__img d-none d-md-block"
	  />
	</v-container>
	<v-container class="d-none d-sm-block home-intro__text relative z-index-2">
	  <v-row>
		<v-col
			cols="12"
			class="home-intro__item home-intro__item--1 d-sm-none d-md-block"
		>
		  <div class="home-intro__title"><h1>Serebro</h1></div>
		  <div class="home-intro__title home-intro__title--1 color-white size-xl">
			Дом в престижном<br/>
			районе Петербурга
		  </div>
		  <router-link :to="{ name: 'flats' }"
		  >
			<btn class="d-none d-md-block"
			>Выбрать квартиру/апартаменты
			</btn
			>
		  </router-link
		  >
		</v-col>
		<v-col cols="12" class="home-intro__item home-intro__item--2">
		  <div
			  class="
              home-intro__stats
              size-sm-md
              text-md-center
              d-sm-none d-md-block
            "
		  >
<!--			<p>
			  2-х уровневые <br> апартаменты высотой до 4,6 м
			</p>-->

			<h3 class="mb-1">640 м<sup>2</sup></h3>
			<p>
			  под кафе, магазин<br/>
			  и коворкинг в доме
			</p>
			<h3 class="mb-1">30 минут</h3>
			<p>
			  на машине до аэропорта<br/>
			  или любого района города
			</p>
			<h3 class="mb-1">145 м<sup>2</sup></h3>
			<p>
			  роскошного лобби
			</p>
		  </div>
		  <!-- <div
			class="
			  home-intro__stats
			  size-sm-md
			  text-lg-center
			  d-none d-sm-block d-md-none
			"
		  >
			<h3>10 минут</h3>
			<p>до метро Пионерская</p>
			<h3>15 минут</h3>
			<p>до Комендантской пл.</p>
			<h3>10 147,9м<sup>2</sup></h3>
			<p>Общая площадь</p>
		  </div> -->
		</v-col>
	  </v-row>
	</v-container>
	<v-container class="d-none d-sm-block home-intro__decor py-0">
	  <v-row>
		<v-col class="py-0 relative">
		  <div absolute class="home-intro__border">
			<!-- <div
			  class="
				home-intro__stats
				size-sm-md
				text-md-center
				d-sm-none d-md-block
			  "
			>
			  <h3 class="mb-2">44 квартиры</h3>
			  <p>
				пентхаусы, 2-уровневые<br />
				апартаменты с террасами
			  </p>
			  <h3 class="mb-2">727 м<sup>2</sup></h3>
			  <p>
				под кафе, магазин<br />
				и коворкинг в доме
			  </p>
			  <h3 class="mb-2">30 минут</h3>
			  <p>
				на машине до аэропорта<br />
				или любого района города
			  </p>
			</div> -->
			<div
				class="
                home-intro__stats
                size-sm-md
                text-lg-center
                d-none d-sm-block d-md-none
              "
			>
			  <h3 class="mb-1">10 минут</h3>
			  <p class="mb-5">до метро Пионерская</p>
			  <h3 class="mb-1">15 минут</h3>
			  <p class="mb-5">до Комендантской пл.</p>
			  <h3 class="mb-1">10 147,9м<sup>2</sup></h3>
			  <p>Общая площадь</p>
			</div>
		  </div>

		</v-col>
	  </v-row>
	</v-container>
  </section>
</template>

<script>
import Btn from "@/components/btn";

export default {
  components: {
	Btn,
  },
};
</script>

<style lang="scss">
.home-intro {
  .size-sm-md {
	@include up($md) {
	  font-size: 18px;
	}

	@include up($lg) {
	  font-size: 24px;
	}
  }

  .btn {
	@include up($md) {
	  justify-content: center;
	  position: absolute;
	  left: 0;
	  background: $white;
	  width: 374px;
	  height: 56px;
	  top: -50px;
	}
	@include up($lg) {
	  top: -130px;
	}
  }

  &__img {
	@include up($sm) {
	  max-width: 85%;
	  // height: 580px;

	  left: -$container-padding-sm;
	}
	@include up($md) {
	  max-width: 980px;
	  height: 600px;
	  left: -$container-padding-md;
	}
	@include up($lg) {
	  max-width: 1400px;
	  height: 800px;
	  left: -36px;
	}
  }

  &__item {
	position: absolute;
	max-width: 340px;
	@include up($sm) {
	  max-width: 724px;
	}
	@include up($md) {
	  max-width: 1180px;
	}
	@include up($lg) {
	  max-width: 1660px;
	}

	&--1 {
	  top: -60px;
	}

	@include up($md) {
	  top: -100px;
	}
	@include up($lg) {
	  top: -10px;
	}

	&--2 {
	  @include up($md) {
		top: -70px;
	  }
	  @include up($lg) {
		top: unset;
	  }
	}
  }

  &__title {
	position: absolute;
	top: 30px;
	left: 24px;
	transform: translateY(30%);
	@include up($md) {
	  top: -500px;
	}
	@include up($lg) {
	  top: -740px;
	}

	&--1 {
	  position: absolute;
	  top: 105px;
	  @include up($md) {
		top: -420px;
	  }
	  @include up($lg) {
		top: -620px;
	  }
	}
  }

  &__stats {
	position: absolute;
	width: 70%;
	min-width: 255px;
	right: 0%;
	top: 0px;
	@include up($sm) {
	  top: 30px;
	  // right: -6%;
	  left: 12%;
	  // margin-left: 60px;
	  width: 10%;
	  // transform: translateX(-10%);
	}
	@include up($md) {
	  width: unset;
	  left: unset;
	  right: 67px;
	  top: -330px;
	  z-index: 4;
	}
	@include up($lg) {
	  right: 115px;
	  // bottom: -10px;
	  top: -465px;
	}
  }

  &__border {
	position: absolute;
	min-width: 275px;
	width: 75%;
	height: 380px;
	right: 7px;
	top: -30px;
	z-index: 1;

	&:before {
	  position: absolute;
	  content: "";
	  width: 100%;
	  height: 100%;
	  border: 1.5px solid transparent;
	  border-image: linear-gradient(309.68deg, #ffffff 10%, #979eb4 84%);
	  -moz-border-image: -moz-linear-gradient(
			  309.68deg,
			  #ffffff 10%,
			  #979eb4 84%
	  );
	  -webkit-border-image: -webkit-linear-gradient(
			  309.68deg,
			  #ffffff 10%,
			  #979eb4 84.1%
	  );
	  border-image-slice: 1;
	}

	@include up($sm) {
	  width: 36%;
	  min-width: 263px;
	  max-width: 330px;
	  height: 312px;
	  top: -220px;
	  right: 15px;
	  z-index: 2;
	}
	@include up($md) {
	  max-width: 350px;
	  min-width: 356px;
	  height: 440px;
	  right: 0;
	  top: -430px;
	  z-index: 1;
	}
	@include up($lg) {
	  min-width: 500px;
	  height: 583px;
	  right: 22px;
	  top: -520px;
	  z-index: 1;
	}
  }

  &__silver {
	position: absolute;

	@include up($sm) {
	  top: -100px;
	  right: 10px;
	  // left: -40px;
	}
	@include up($md) {
	  top: -110px;
	  right: 0;
	}
	@include up($lg) {
	  top: -110px;
	  right: 15px;
	}
  }

  &__decor {
	height: 140px;
  }
}
</style>