<template>
	<div class="flats-search-popup">
		<div class="flats-search-popup__left">
			<span class="d-block mb-3">Очередь {{ queue }}</span>
			<p class="h2 mb-5">Этаж {{ level.NAME }}</p>

			<p class="size-xl mb-5">Апартаменты {{ item.NAME }}</p>

			<!--      <div class="flats-search-popup__prices mb-5">
					<div><span>Цена</span><span>10 000 000 Р</span></div>
					<div><span>Цена за м2</span><span>345 000 Р</span></div>
					<div><span>Общая площадь</span><span>{{ item.flatSquare }} м2</span></div>
				  </div>-->

			<p v-for="(item, index) in item.OPTIONS"
			   :key="index"
			   class="size-sm color-dark-gray mb-1"
			>
				{{ item }}
			</p>
			<p v-if="item.TYPE" class="size-sm color-dark-gray mb-1">{{ item.TYPE }}</p>
			<p v-if="item.SQUARE" class="size-sm color-dark-gray mb-1">Общая площадь: {{ item.SQUARE }}</p>
			<p  class="size-sm color-dark-gray mb-0">Высота потолка: {{ apartmentHeight }} м</p>

			<div v-if="item.PRICE" class="flats-search__apart-price mt-5 size-sm">
				<div class="d-flex justify-space-between color-black">
					<span>Цена за м<sup>2</sup></span>
					<span>{{ item.PRICE_FOR_M | nf }}</span>
				</div>
				<div class="d-flex justify-space-between">
					<span>Цена</span>
					<span>{{ item.PRICE | nf }}</span>
				</div>

			</div>

			<div class="flats-search-popup__btns">
				<popup title="Записаться на просмотр" class="mb-4">Записаться на просмотр</popup>
				<popup v-if="queue === '2' && (level.NAME === '3' || level.NAME === '4' || level.NAME === '5')"
					   heading="true"
					   title="Узнать цену"
				>Узнать цену</popup>
				<popup v-else title="Задать вопрос">Задать вопрос</popup>
			</div>
		</div>
		<div class="flats-search-popup__middle">
			<!--			<div v-if="item.mezzanine" class="flats-search-popup__middle-text">
							<span>1 этаж</span>
							<span>антресоль</span>
						</div>
						<v-img :src="item.typeSrc" contain/>-->
			<div  class="flats-search-popup__wrap">
				<div v-if="imgPlan2" class="flats-search-popup__plans">
					<v-img :src="'https://serebro-avenue.ru' + imgPlan2(item)" contain/>
				</div>
				<v-img :src="'https://serebro-avenue.ru' + imgPlan(item)" contain/>

			</div>
			<span class="flats-search-popup__decor">Серебристый бульвар</span>

			<div class="flats-search-popup__visual">
				<v-img @click="openImageFullScreen('https://serebro-avenue.ru' + item)"
					   v-for="(item, index) of getVisual(item)" :key="index"
					   class="d-none d-md-block"
					   :src="'https://serebro-avenue.ru' + item"/>
			</div>
		</div>
		<div class="flats-search-popup__right">
			<v-icon @click="dialogClose"
					class="flats-search-popup__right-icon"
					size="32"
			>
				mdi-close
			</v-icon>
		</div>
	</div>
</template>

<script>
import Popup from "@/components/popup";
import {nf} from "@/utils/string";

export default {
	name: "flats-search-popup",
	components: {Popup},
	props: {
		item: Object,
	},
	computed: {
		level() {
			return this.$store.state.page?.LevelItemController?.levels.find(el => el.ID === this.$store.state.page?.LevelItemController?.item.ID);
		},
		queue() {
			if (this.$store.state.page?.LevelItemController?.item.STAGE === '37') return '2'
			else return '1';
		},
		apartmentHeight() {
			if (this.$store.state.page?.LevelItemController?.item.STAGE === '37') return '4.6'
			else return '2.9';
		},
		appartments() {
			return this.$store.state.page?.LevelItemController?.appartments.map(el => (el.VISUAL));
		},
		plan() {
			return this.$store.state.page?.LevelItemController?.plans;
		},
		visuals() {
			return this.$store.state.page?.LevelItemController?.visuals;
		},
	},
	methods: {
	  openImageFullScreen(imageSrc) {
		const backgroundOverlay = document.createElement('div');
		backgroundOverlay.style.position = 'fixed';
		backgroundOverlay.style.top = '0';
		backgroundOverlay.style.left = '0';
		backgroundOverlay.style.width = '100%';
		backgroundOverlay.style.height = '100%';
		backgroundOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
		backgroundOverlay.style.zIndex = '9998';
		backgroundOverlay.style.cursor = 'pointer';

		backgroundOverlay.addEventListener('click', () => {
		  fullScreenImage.style.width = '0';
		  fullScreenImage.style.height = '0';
		  backgroundOverlay.remove();
		  setTimeout(() => {
			fullScreenImage.remove();
		  }, 300);
		});

		document.body.appendChild(backgroundOverlay);

		const fullScreenImage = document.createElement('img');
		fullScreenImage.src = imageSrc;
		fullScreenImage.style.maxWidth = '80%';
		fullScreenImage.style.maxHeight = '80%';
		fullScreenImage.style.width = '0';
		fullScreenImage.style.height = '0';
		fullScreenImage.style.position = 'fixed';
		fullScreenImage.style.top = '50%';
		fullScreenImage.style.left = '50%';
		fullScreenImage.style.objectFit = 'contain';
		fullScreenImage.style.transform = 'translate(-50%, -50%)';
		fullScreenImage.style.transition = 'width 0.3s, height 0.3s';
		fullScreenImage.style.zIndex = '9999';
		fullScreenImage.style.cursor = 'pointer';

		fullScreenImage.addEventListener('click', () => {
		  fullScreenImage.style.width = '0';
		  fullScreenImage.style.height = '0';
		  backgroundOverlay.remove();
		  setTimeout(() => {
			fullScreenImage.remove();
		  }, 300);
		});

		document.body.appendChild(fullScreenImage);
		setTimeout(() => {
		  fullScreenImage.style.width = '100%';
		  fullScreenImage.style.height = '100%';
		}, 0);
	  },
		dialogClose() {
			this.$emit('flatCard');
		},
		imgPlan(item) {
			if (item.PLAN) return this.plan.find(el => el.ID === item.PLAN).IMAGES[0]
			else return;
		},
		imgPlan2(item) {
			if (item.PLAN) return this.plan.find(el => el.ID === item.PLAN).IMAGES[1]
			else return;
		},
		getVisual(item) {
			if (item.VISUAL) return this.visuals.find(el => el.ID === item.VISUAL).PHOTOS
			else return;
		},
	},
	mounted() {

	}
}
</script>

<style lang="scss">
.flats-search-popup {
	padding: 44px;
	background: $white;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__visual {
		margin-top: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
		& .v-image {
			width: 250px;
			height: 200px;
			cursor: pointer;
		}
	}

	&__left {
		width: 340px;
		padding: 20px;
	}

	&__wrap {
		display: flex;
	}

	&__prices {
		div {
			display: flex;
			justify-content: space-between;
		}
	}

	&__btns {
		margin-top: 48px;

		.popup {
			.v-btn {
				max-width: unset;
			}
		}
	}

	&__middle {
		&-text {
			color: $mid-grey;
			display: flex;
			justify-content: space-around;
		}

		.v-image {
			max-width: 200px;
			max-height: 300px;
			@include up($md) {
				max-width: 400px;
			}

		}
	}

	&__right {
		align-self: flex-start;

		.v-icon {
			color: $black;
			width: 24px;
			height: 24px;
		}
	}

	//todo component
	&__decor {
		text-align: center;
		display: block;
		position: relative;
		color: $mid-grey;
		margin-top: 20px;

		&::before,
		&::after {
			position: absolute;
			content: "";
			width: 20%;
			left: 15%;
			top: calc(50% - 0.5px);
			height: 1px;
			background: $mid-grey;
		}

		&::after {
			left: auto;
			right: 15%;
		}
	}

}
</style>