<template>
  <section class="news">
	<v-container>
	  <h2 class="h1 mb-8 mb-md-16">События и новости</h2>
	  <ul>
		<li v-for="(item, index) of visibleNews" :key="index">
		  <div class="news__content ">
			<router-link :to="'news/' + item.CODE">
			  <v-img :src="item.PREVIEW_PICTURE"/>
			  <div class="news__content-text">
				<p class="font1"> {{ item.NAME }} </p>
			  </div>
			  <span class="size-xs"> {{ formatDate(item.ACTIVE_FROM) }}</span>
			</router-link>
		  </div>
		</li>
	  </ul>
	  <div @click="showAllNews" class="news__btn-all" v-if="hiddenNews.length > 0 && !showHiddenNews">
		<btn-form>Показать еще {{ hiddenNews.length }}</btn-form>
	  </div>
	  <ul v-if="showHiddenNews">
		<li v-for="(item, index) in hiddenNews" :key="index">
		  <div class="news__content">
			<router-link :to="'news/' + item.CODE">
			  <v-img :src="item.PREVIEW_PICTURE"/>
			  <div class="news__content-text">
				<p class="font1">{{ item.NAME }}</p>
			  </div>
			  <span class="size-xs">{{ formatDate(item.ACTIVE_FROM) }}</span>
			</router-link>
		  </div>
		</li>
	  </ul>
	</v-container>
  </section>
</template>

<script>
import Btn from "@/components/btn";
import BtnForm from "@/components/btn-form.vue";

export default {
  components: {
	BtnForm,
	Btn,
  },
  data() {
	return {
	  showHiddenNews: false,
	};
  },
  computed: {
	news() {
	  return this.$store.state.page?.PubsController?.items;
	},
	visibleNews() {
	  return this.news.slice(0, 6);
	},
	hiddenNews() {
	  return this.news.slice(6);
	},
  },
  methods: {
	showAllNews() {
	  console.log("Остальные новости")
	  this.showHiddenNews = true;
	},
	formatDate(dateString) {
	  const months = [
		'января', 'февраля', 'марта',
		'апреля', 'мая', 'июня',
		'июля', 'августа', 'сентября',
		'октября', 'ноября', 'декабря'
	  ];

	  const [day, month, year] = dateString.split('.');
	  const monthIndex = parseInt(month, 10) - 1;

	  return `${parseInt(day, 10)} ${months[monthIndex]} ${year} г.`;
	}
  },
}
</script>


<style lang="scss">
.news {
  color: $black;
  @include up($md) {
	margin-top: 80px;
	margin-bottom: 156px;
  }

  .h1 {
	color: $black;
  }

  ul, li {

	padding: unset;
	list-style: none;
	margin: 0;


  }

  ul {
	@include up($sm) {
	  display: grid;
	  grid-template-columns: repeat(2, 1fr);
	}
	@include up($md) {
	  grid-template-columns: repeat(3, 1fr);
	}
  }

  li {
	width: 250px;
	@include up($md) {
	  width: 350px;
	}
	@include up($lg) {
	  width: 496px;
	}

	&:not(last-child) {
	  margin-bottom: 25px;
	}
  }

  .v-image {
	height: 100%;
	max-width: 250px;
	margin-bottom: 15px;
	@include up($sm) {
	  max-height: 180px;
	}
	@include up($md) {
	  max-width: 350px;
	}
	@include up($lg) {
	  max-height: 330px;
	  max-width: none;
	  width: 496px;
	}
  }

  &__btn-all {
	justify-content: center;
	display: flex;
  }

  &__content {
	height: 100%;
	margin-bottom: 40px;
	@include up($sm) {
	  margin-bottom: 80px;
	}
	@include up($md) {
	  margin-bottom: 60px;
	}
	@include up($lg) {
	  margin-bottom: 80px;
	}

	&-text {
	  margin-bottom: 10px;
	  height: 100%;
	  max-height: 90px;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  @include up($sm) {
		margin-bottom: 4px;
	  }
	  @include up($md) {
		margin-bottom: 16px;
	  }

	}
  }
}
</style>