<template>
    <div class="project">
        <project-about/>
        <project-location/>
        <project-style/>
        <project-lobby/>
        <project-service/>
        <project-places/>
        <project-coworking/>
        <project-safety/>
	  <scrollButton />
<!--        <project-progress/>-->
    </div>
</template>
<script>
import ProjectAbout from "@/components/Project/project-about";
import ProjectLocation from "@/components/Project/project-location";
import ProjectStyle from "@/components/Project/project-style";
import ProjectPlaces from "@/components/Project/project-places";
import ProjectCoworking from "@/components/Project/project-coworking";
import ProjectSafety from "@/components/Project/project-safety";
import ProjectProgress from "@/components/Project/project-progress";
import ProjectLobby from "@/components/Project/project-lobby";
import ProjectService from "@/components/Project/project-service";
import ScrollButton from "@/components/scrollButton.vue";
export default {
    components: {
	  ScrollButton,
        ProjectService,
        ProjectLobby, ProjectStyle, ProjectLocation, ProjectAbout, ProjectPlaces, ProjectCoworking, ProjectSafety, ProjectProgress}
}
</script>

<style lang="scss">
.project {
    overflow: hidden;
    background: $sky-blue;

    &-about {
        padding-top: 16px;
        padding-bottom: 32px;
        @include up($md) {
            padding-top: 180px;
            padding-bottom: 160px;
        }
        @include up($lg) {
            padding-bottom: 0;
        }
    }

    & section {
        padding-bottom: 80px;
        @include up($md) {
            padding-bottom: 160px;
        }
    }

    &-coworking {
        padding-bottom: 80px;
        @include up($sm) {
            padding-bottom: 60px;
            margin-bottom: 100px;
        }
        @include up($md) {
            margin-bottom: 160px;
        }
    }


}
</style>