<template>
    <section class="howtobuy-questions">
        <v-container class="howtobuy-questions__container">
            <v-row>
                <v-col cols="12" md="6">
                    <h2 class="mb-10">Остались вопросы?</h2>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="howtobuy-questions__content">
                        <p class="mb-5 mb-lg-8">
                            Персональный менеджер ответит на все вопросы и подготовит для вас индивидуальное
                            предложение.
                        </p>
                        <a class="mb-12 mb-lg-8 d-flex align-center h4" :href="'tel:'+ $store.state.PHONE.replace(/[^0-9]/g, '')">
                            <svg class="mr-3" width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M29.0095 35.6665L29.0019 35.6665C22.4622 35.6581 16.1927 33.0564 11.5684 28.4321C6.94428 23.8079 4.34264 17.5387 4.33398 10.9992C4.33415 9.23135 5.03651 7.53591 6.28661 6.28582C7.53685 5.03558 9.23254 4.3332 11.0007 4.3332V4.33323L11.0083 4.33317C11.3733 4.33039 11.7377 4.36352 12.0962 4.43207L12.1168 4.43602L12.1376 4.43909C12.4817 4.49002 12.82 4.57459 13.1476 4.6916L13.1476 4.69161L13.1529 4.69347C13.2624 4.73189 13.36 4.79822 13.436 4.88592C13.512 4.97361 13.5638 5.07962 13.5862 5.19347L13.5891 5.20801L13.5924 5.22247L15.8754 15.221C15.8754 15.2212 15.8755 15.2214 15.8755 15.2216C15.9 15.33 15.897 15.4428 15.8668 15.5497C15.8365 15.6569 15.7799 15.7547 15.7021 15.8343L15.6932 15.8434L15.6845 15.8527C15.6548 15.8847 15.6457 15.8943 15.6386 15.9013C15.6356 15.9043 15.6355 15.9043 15.6343 15.9053C15.6267 15.9113 15.589 15.94 15.4616 16.0139C15.1992 16.166 14.709 16.4234 13.6724 16.9628L12.8303 17.4009L13.2241 18.2646C14.9651 22.0841 18.0198 25.1514 21.8322 26.9081L22.6952 27.3058L23.1363 26.4641C23.684 25.4194 23.9457 24.9246 24.1001 24.6596C24.1752 24.5307 24.2046 24.492 24.2114 24.4836C24.2126 24.482 24.2128 24.4817 24.2158 24.4786C24.2228 24.4715 24.2325 24.4624 24.2644 24.4327L24.2738 24.424L24.2829 24.4151C24.3625 24.3373 24.4603 24.2807 24.5675 24.2504C24.6744 24.2202 24.7872 24.2172 24.8955 24.2416C24.8958 24.2417 24.896 24.2417 24.8962 24.2418L34.8913 26.524C34.8918 26.5241 34.8923 26.5242 34.8928 26.5243C35.0024 26.55 35.1038 26.6031 35.1874 26.6786C35.2713 26.7544 35.3346 26.8503 35.3713 26.9573L35.3712 26.9573L35.3751 26.9682C35.4959 27.3078 35.5869 27.6572 35.6473 28.0125C35.7051 28.3749 35.7341 28.7413 35.734 29.1083C35.7055 30.8663 34.9836 32.542 33.7254 33.7704C32.4655 35.0004 30.7701 35.6821 29.0095 35.6665Z"
                                    stroke="url(#paint0_linear_310_8255)" stroke-width="2"/>
                                <defs>
                                    <linearGradient id="paint0_linear_310_8255" x1="3.33398" y1="36.6668" x2="40.458"
                                                    y2="8.18734" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#979EB4"/>
                                        <stop offset="1" stop-color="#F2F2F2"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            {{ $store.state.PHONE }}
                        </a>
                        <!-- <btn-form>Заказать звонок</btn-form> -->
                        <popup title="Остались вопросы?" justify="start">Заказать звонок</popup>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style lang="scss">
.howtobuy-questions {

    & .popup {
        max-width: 360px;
    }

    /*& .container {
        max-width: unset;
        padding: 0 24px;
        @include up($md) {
            padding: 0;
            max-width: 1200px;
        }
        @include up($lg) {
            max-width: 1720px;
        }
    }*/

    a {
        color: $black !important;
        text-decoration: none;
    }

    h2 {
        @include up($lg) {
            max-width: 360px;
            margin-left: auto;
        }
    }

    &__content {
        @include up($lg) {
            max-width: 500px;
            margin-right: auto;
        }
    }
}
</style>
<script>
import BtnForm from "@/components/btn-form";
import Popup from '@/components/popup';

export default {
    components: {
        BtnForm,
        Popup
        }
}
</script>