<template>
    <section class="project-location">
<!--        <div class="project-location__map d-sm-none">
            <MglMap
                container="project-location__mapbox"
                :accessToken="accessToken"
                :mapStyle.sync="style"
                :center.sync="center"
                :zoom.sync="zoom"
                :scrollZoom="scrollZoom"
                @load="onMapLoaded">
                <MglMarker
                    :coordinates="point.geo"
                    :offset="point.offset"
                    color="black"/>
                <MglNavigationControl position="top-right"/>
            </MglMap>
        </div>-->

        <v-container>

            <v-row class="align-center">
                <v-col cols="12" md="6">
                    <div class="project-location__map">
                        <MglMap
                            :accessToken="accessToken"
                            :mapStyle.sync="style"
                            :center.sync="center"
                            :zoom.sync="zoom"
                            :scrollZoom="scrollZoom"
                            @load="onMapLoaded">
                            <MglMarker
								v-for="(marker, index) of markers"
								:key="index"
								:coordinates="marker.geo"
								:offset="marker.offset"
								color="black"/>
                            <MglNavigationControl position="top-right"/>
                        </MglMap>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="relative z-index-2">
                    <div class="project-location__text px-sm-7 py-sm-10">
                        <h2>Удобное расположение</h2>
                        <address class="size-sm mt-8 mb-8">
                            Санкт-Петербург, Серебристый бульвар 18к1
                        </address>
                        <p class="size-sm">Комплекс расположен в пешей доступности от метро Пионерская и Комендантский
                            проспект в
                            живописном месте, где много зелени, мало промышленных предприятий. Неподалеку расположен
                            Удельный парк и ЦПКиО.</p>
                        <p class="size-sm">
                            Здесь развита торговая и транспортная инфраструктура. Работают
                            популярные гипермаркеты и торговые центры, многочисленные магазины
                            «у дома». Благодаря удобному выезду на ЗСД, можно без пробок
                            добраться до аэропорта или любого другого района города в среднем
                            за 30 минут.
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>


<script>
import BtnForm from "@/components/btn-form";
import Config from "@/config";
import Mapbox from "mapbox-gl";
import {MglMap, MglMarker, MglPopup, MglNavigationControl, MglGeolocateControl} from "vue-mapbox";

let mapbox;

export default {
    components: {
        BtnForm,
        MglMap,
        MglMarker,
        MglNavigationControl,
		MglPopup,
		MglGeolocateControl
    },
    data() {
        return {
            scrollZoom: false,
            accessToken: Config.MAPBOX.accessToken,
            style: Config.MAPBOX.style,
            center: [30.279675, 60.003912],
            zoom: 13,
			point: {
				//offset: [6, -10],
				//offsetPopup: [-10, -16],
				//geo: [30.279675, 60.003912],
			},
			markers: [
				{
					offset: [6, -10],
					geo: [30.279675, 60.003912],
					img: '@/assets/Project/project-location/house.png'
				},
				{
					offset: [6, -10],
					geo: [30.296666, 60.002518],
					img: '@/assets/Home/Spb-metro-logo.svg'
				},
				{
					offset: [6, -10],
					geo: [30.258027, 60.008573],
					img: '@/assets/Home/Spb-metro-logo.svg'
				},
			]
        }
    },

    created() {
        // We need to set mapbox-gl library here in order to use it in template
        this.mapbox = Mapbox;
    },

    methods: {
        onMapLoaded(event) {
            this.map = event.map;
            // or just to store if you want have access from other components
            this.$store.map = event.map;
        },
    },

};
</script>

<style lang="scss">
.project {

	& .mapboxgl-marker {
		& svg {
			background-image: url("@/assets/Home/Spb-metro-logo.svg");
			width: 50px;
			height: auto;
			background-size: contain;

			& ellipse,
			& path,
			& circle {
				display: none;
			}
		}

	}
	& .mapboxgl-marker:nth-child(2) {
		& svg {
			background-image: url("@/assets/Project/project-location/house.png");
			width: 134px;
			height: 89px;
			background-size: cover;

			& ellipse,
			& path,
			& circle {
				display: none;
			}
		}

	}
	& .mapboxgl-marker:nth-child(3) {
		& svg {
			background-image: url("@/assets/Home/Spb-metro-logo.svg");
			width: 50px;
			height: auto;
			background-size: contain;

			& ellipse,
			& path,
			& circle {
				display: none;
			}
		}

	}

    &-location {
        position: relative;

        &__map {
            height: 50vh; //calc(100vh - 119px);
            max-height: 800px;
            position: relative;
            @include up($md) {
				height: 80vh;
            }
        }

        &__content {
            @include up($md) {
                //position: absolute;
                //top: 15%;
                //width: 100%;
            }
        }

        &__text {
            @include up($md) {
                //position: absolute;
                border: 1px solid $dark-grey;
                right: 0;
                top: 10%;
            }
            @include up($lg) {
                //position: absolute;
                border: 1px solid $dark-grey;
                left: -100px;
                top: 15%;
            }
        }
    }
}
</style>